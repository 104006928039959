@import "./index.scss";
li,
a {
    // color: #FFF;
}

.container {
    width: 90%;
    max-width: 1300px;
    padding-top: 2em;
    padding-bottom: 2em;
}

@media screen and (max-width: 1600px) {
    .container {
        width: calc(100% - 2em);
    }
}

@media screen and (max-width: 1366px) {
    .container {
        width: calc(90% - 2.5em);
    }
}

@media screen and (max-width: 1366px) {
  .container {
      width: calc(100% - 2.5em);
  }
}

@media screen and (max-width: 768px) {
  .container {
      width: calc(100% - 2em);
      padding-top: 1em;
      padding-bottom: 1em;
      padding-left: 0;
      padding-right: 0;
  }
}

.card {
    display: block;
    flex-direction: initial;
    border: none;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

body.dark-mode .card {
    background: $dark-color-second;
    box-shadow: $box-shadow-dark;
}

.content-panel {
    float: left;
    width: calc(100% - 17%);
    margin-left: 17%;
}

.content-panel .container:first-child {
    padding-top: 0;
}

@media screen and (max-width: 1600px) {
    .content-panel {
        width: calc(100% - 19%);
        margin-left: 19%;
        padding: 0;
    }
}

@media screen and (max-width: 1366px) {
    .content-panel {
        width: calc(100% - 23%);
        margin-left: 23%;
    }
}

@media screen and (max-width: 1250px) {
    .content-panel {
        width: calc(100% - 22%);
        margin-left: 22%;
    }
}

@media screen and (max-width: 1024px) {
  .content-panel {
      width: calc(100% - 26%);
      margin-left: 26%;
  }
}

@media screen and (max-width: 768px) {
    .content-panel {
        float: initial;
        width: 100%;
        margin-top: 1em;
        margin-left: 0;
    }
}

.li-menu{
    display: flex;
    justify-content: space-between;
    padding: .8em 1em;
    border-bottom: 1px solid #CCC;
}

.li-menu svg{ 
    width: 18px;
    height: 18px;
}

.temp{
    position: absolute;
    top: 10em;
    right: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: blue;
}

// NEON MODE
body.neon-mode .card{
    background: rgba(255, 255, 255, .1);
    background: #0E1117;
    box-shadow: none;
}

body.neon-mode .card::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 80px;
    height: 80px;
    background: #7474e4;
    // background: #6868a2;
    filter: blur(7px);
    z-index: -1;
}

body.neon-mode .card::after{
    content: '';
    position: absolute;
    /* top: 0; */
    right: 3px;
    bottom: 0;
    width: 80px;
    height: 80px;
    background: #E6DF6E;
    filter: blur(7px);
    z-index: -1;
}