@import "../../index.scss";

.mobile-menu{
    position: fixed;
    width: 100%;
    height: 55px;
    bottom: 0;
    background: #FFF;
    border-top: 1px solid #CCC;
    z-index: 9;
}

.mobile-menu .container{
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.mobile-menu .row{
    align-items: center;
    height: 100%;
    text-align: center;
}

.mobile-menu .col-md-4{
    flex: 0 0 33.33%;
    max-width: 33.33%;
}

.mobile-menu svg{
    width: 22px;
    height: 22px;
}


// DARK MNODE
body.dark-mode .mobile-menu{
    background: $dark-color-primary;
    border-top: 1px solid #5C5C5C;
}

// NEON MODE
body.neon-mode .mobile-menu{
    background: $neon-color-primary;
    border-top: 1px solid #5C5C5C;
}