.discover-panel .col-md-6{
    width: 50%;
}

.discover-panel li .col-md-6:nth-child(2){
    text-align: right;
}

.discover-panel li label{
    margin-bottom: 0;
}