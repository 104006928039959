.navbar{
    // display: none;
}

.back-header{
    position: fixed;
    top: 0;
    width: 100%;
    height: 45px;
}

.back-header .container{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    padding-top: 0;
    padding-bottom: 0;
}

.back-header .container .back-arrow{
    position: absolute;
    left: 0;
}

.back-header .container h6{
    margin-bottom: 0 !important;
}

