@import "../../../index.scss";
.language-panel {
    border-radius: $border-radius;
    box-shadow: 0 0 10px #CCC;
}

.language-panel li {
    list-style-type: none;
    padding: .7em 1em;
    border-bottom: 1px solid #CCC;
}

.language-panel li:last-child {
    border-bottom: none;
}