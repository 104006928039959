// @import "../../index.scss";
.futurist-panel .col-md-3 {
    flex: 0 0 20%;
    max-width: 20%;
}

@media screen and (max-width: 768px) {
    .futurist-panel .col-md-3 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.card-futurist {
    min-height: 190px;
    margin-bottom: 1.5em;
    padding: 1.3em 1em;
    text-align: center;
}

.card-futurist .create-coin {
    position: absolute;
    color: #ffb631;
}

.card-futurist .profile-picture {
    width: 50px;
    height: 50px;
    margin: 0 auto 1em auto;
    background: #f6f6f6;
    border-radius: 15px;
    overflow: hidden;
}

.card-futurist .profile-picture img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-futurist p {
    color: inherit;
    margin-bottom: 5px;
}

.card-futurist .link-panel {
    position: absolute;
    left: 0;
    right: 0;
    max-width: auto;
    bottom: 1em;
}

.card-futurist .link-panel li {
    display: inline-block;
    list-style: none;
    width: 24px;
    height: 24px;
    margin-right: 5px;
    text-align: center;
    border-radius: 8px;
}

.card-futurist .link-panel li a svg {
    width: 16px;
}

@media screen and (max-width: 768px) {
    .card-futurist {
        min-height: initial;
    }
    .card-futurist p {
        display: none;
    }
    .card-futurist .link-panel {
        position: initial;
    }
}