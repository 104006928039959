@import "../../../index.scss";
.discover-panel {
    border-radius: $border-radius;
    box-shadow: 0 0 10px #CCC;
}

.list-page {
    margin-top: -1px;
    padding: 1em;
    border-bottom: 1px solid #CCC;
}

.discover-panel .list-page:last-child {
    border-bottom: none;
}

.list-page li {
    list-style-type: none;
    font-weight: 600;
}

body.dark-mode .discover-panel {
    box-shadow: $box-shadow-dark;
    background: $dark-color-second;
}

// body.neon-mode .list-page li a{
//     background: -webkit-linear-gradient(#E6DF6E, #fff);
//     -webkit-background-clip: text;
//     -webkit-text-fill-color: transparent;
//     text-shadow: 0 0 3px #fff;
// }
body.neon-mode .discover-panel {
    box-shadow: 0 0 7px #7474e4;
}


// .discover-panel .col-md-6{
//     flex: 0 0 50% !important;
//     max-width: 50% !important;
// }
// .discover-panel .col-md-6:nth-child(odd){
//     padding-right: 10px;
// }
// .discover-panel .col-md-6:nth-child(even){
//     padding-left: 10px;
// }