@import "../../index.scss";
.navbar {
    width: calc(100% - 17%);
    height: 70px;
    background: #FFF;
    margin-left: 17%;
    border-bottom: none;
    padding: 0;
}

.navbar ul {
    display: inline-block;
    padding-left: 0;
}

.navbar ul li {
    display: inline-block;
}

.navbar>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
}

.temp {
    font-size: 1.15rem;
    font-weight: 700;
    vertical-align: middle;
}

.navbar li {
    list-style-type: none;
}

.navbar li a {
    text-decoration: none;
}

.navbar svg {
    width: 23px;
    stroke-width: 1.5;
}

.logo {
    width: 70px;
}

.navbar .container {
    padding-top: 0;
    padding-bottom: 0;
}

.navbar .left-menu {
    display: flex;
    align-items: center;
}

.navbar ul li {
    position: relative;
    margin-right: 1.5em;
    font-weight: 500;
    cursor: pointer;
}

.dd-language {
    position: absolute !important;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    // vertical-align: middle;
    cursor: pointer;
}

.dd-language .Dropdown-control {
    background: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
}

.dd-language .Dropdown-placeholder.is-selected{
    margin-left: 1.6em;
}

.dd-language .Dropdown-control .Dropdown-arrow {
    display: none;
}

.dd-language .Dropdown-menu {
    width: 130px;
    top: 3.5em;
    border: none;
    border-radius: 10px;
    box-shadow: $box-shadow;
}

.li-language{
    width: 110px;
    margin-right: 5px !important;
}

.right-menu li:last-child{
    margin-right: 0;
}

@media screen and (max-width: 1366px) {
    .navbar {
        width: calc(100% - 23%);
        margin-left: 23%;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        position: fixed;
        top: 0;
        width: 100%;
        height: 50px;
        margin-left: 0;
        // border-bottom: 1px solid #CCC;
        z-index: 19;
    }
    .navbar h1 {
        font-size: 1.2rem !important;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
    }
    // .navbar .container{
    //     width: 100%;
    //     margin-left: 0;
    //     border-bottom: 1px solid #CCC;
    // }
}

// DARK THEME
body.dark-mode .navbar {
    background: $dark-color-primary;
}

body.dark-mode .dd-language .Dropdown-menu {
    box-shadow: $box-shadow-dark;
}

body.dark-mode .dd-language .Dropdown-control {
    color: #FFF;
}

body.dark-mode .dd-language .Dropdown-menu,
body.dark-mode .dd-language .Dropdown-menu .Dropdown-option.is-selected {
    background: $dark-color-second;
    color: #FFF !important;
}

body.dark-mode .dd-language .Dropdown-option {
    color: #FFF;
}

body.dark-mode .dd-language .Dropdown-option:hover {
    background: #212121 !important;
}

// NEON THEME
// body .navbar .container{
//     background: #ccc !important;
// }

body.neon-mode .navbar {
    background: $neon-color-primary;
}

body.neon-mode .navbar h1{
    background: -webkit-linear-gradient(#E6DF6E, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 3px #fff;
}

body.neon-mode .dd-language .Dropdown-menu {
    box-shadow: $box-shadow-dark;
}

body.neon-mode .dd-language .Dropdown-control {
    color: #FFF;
}

body.neon-mode .dd-language .Dropdown-menu,
body.neon-mode .dd-language .Dropdown-menu .Dropdown-option.is-selected {
    background: $dark-color-second;
    color: #FFF !important;
}

body.neon-mode .dd-language .Dropdown-option {
    color: #FFF;
}

body.neon-mode .dd-language .Dropdown-option:hover {
    background: #212121 !important;
}

body.neon-mode .navbar ul li{
    text-shadow: 0 0 5px #FFF;
}