@import "../../index.scss";
.banner {
    height: 250px;
    background: #F1F1F1;
    border-radius: $border-radius;
    margin-bottom: 2em;
}

.platform-panel {
    float: left;
    width: 100%;
    margin-top: .3em;
    // padding-left: 2em;
}

@media screen and (max-width: 1400px) {
    .platform-panel {
        margin-top: 0;
    }
}

@media screen and (max-width: 768px) {
    .banner {
        height: 150px;
        margin-bottom: 1.5em;
    }
}

.platform-list {
    margin-bottom: 1.5em;
    padding: 1.2em;
    box-shadow: 0 0 10px #CCC;
    border-radius: $border-radius;
    cursor: pointer;
}

.platform-list .left-panel,
.platform-list .right-panel {
    float: left;
}

.platform-list .left-panel {
    width: 12%;
}

.platform-list .right-panel {
    width: 85%;
}

.platform-list .logo-box {
    position: relative;
    width: 100%;
    padding-top: 100%; // 1:1 ratio
    border-radius: 7px;
}

.platform-list .logo-box img {
    // 1:1 ratio
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.platform-list .platform-summary {
    padding-left: .7em;
}

.platform-list p {
    margin-bottom: 0;
}

.platform-list .link-panel {
    margin-top: 8px;
}

.platform-list .link-panel li {
    display: inline-block;
    list-style: none;
    width: 24px;
    height: 24px;
    // background: rgba(255, 255, 255, .4);
    margin-right: 5px;
    text-align: center;
    border-radius: 8px;
}

.platform-list .link-panel li a svg {
    width: 16px;
}

.platform-list .link-panel li a .fa-icon {
    width: initial;
    height: 18px;
}

@media screen and (max-width: 1400px) {
    .platform-list {
        padding: 1em 1.2em;
    }
    .platform-list .platform-summary {
        padding-left: 1em;
    }
}

@media screen and (max-width: 1366px) {
    .platform-panel .row .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 1250px) {
    .platform-panel .row .col-md-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .platform-panel .row .col-md-4 {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .platform-list {
        margin-bottom: 1em;
        padding: 1em;
    }
    .platform-list .logo-box img {
        width: 80%;
    }
    .platform-list .platform-summary {
        padding-left: .5em;
    }
    // .platform-list .left-panel{
    // }
    .platform-list .link-panel li a svg {
        width: 18px;
    }
}

.category-tag {
    display: inline-block;
    background: #DDDDFF;
    color: #7769D0;
    padding: 0 10px;
    border-radius: 5px;
    margin-top: 8px;
    margin-right: 5px;
}

.platform-list.loading .logo-box,
.platform-list.loading h6,
.platform-list.loading p,
.platform-list.loading .link-panel div,
.platform-list.loading .category-tag {
    background: rgba(0, 0, 0, .05);
}

// Dark Mode
body.dark-mode .platform-list .link-panel li {
    // background: rgba(0, 0, 0, .5);
}

body.dark-mode .category-tag {
    background: #3c3c48;
    // color: #9a91cf;
    color: #ffb631;
    // background: #3b933b;
    // color: #fff;
}

body.dark-mode .platform-list.loading .logo-box,
body.dark-mode .platform-list.loading h6,
body.dark-mode .platform-list.loading p,
body.dark-mode .platform-list.loading .link-panel div,
body.dark-mode .platform-list.loading .category-tag {
    background: rgba(255, 255, 255, .02);
}

// Neon Mode
body.neon-mode .platform-list h6 {
    background: -webkit-linear-gradient(#E6DF6E, #fff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-shadow: 0 0 3px #fff;
}

body.neon-mode .category-tag {
    background: #18182e;
    // color: #d3ccff;
    color: #bcb1ff;
    // color: #b8b03b;
}

body.neon-mode .platform-list.loading .logo-box,
body.neon-mode .platform-list.loading h6,
body.neon-mode .platform-list.loading p,
body.neon-mode .platform-list.loading .link-panel div,
body.neon-mode .platform-list.loading .category-tag {
    background: rgba(255, 255, 255, .02);
}

// Loading
.platform-list.loading {
    height: 173px;
}

@keyframes blinker {
    to {
        opacity: 0.6;
    }
}

.platform-list.loading .logo-box {
    animation: blinker .9s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

.platform-list.loading h6 {
    width: 45%;
    height: 17px;
    border-radius: 5px;
    margin-bottom: 10px !important;
    animation: blinker .9s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

.platform-list.loading p {
    width: 100%;
    height: 50px;
    border-radius: $border-radius;
    animation: blinker .9s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

.platform-list.loading .link-panel div {
    display: inline-block;
    width: 20%;
    height: 16px;
    border-radius: 5px;
    margin-right: 5px;
    animation: blinker .9s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}

.platform-list.loading .category-tag {
    width: 30%;
    height: 16px;
    border-radius: 6px;
    animation: blinker .9s cubic-bezier(.5, 0, 1, 1) infinite alternate;
}