@import "../../../index.scss";
.sidebar-panel {
    float: left;
    width: 15%;
    height: 100vh;
    border-right: 1px solid #CCC;
}

.banner{
    height: 250px;
    background: #F1F1F1;
    border-radius: $border-radius;
    margin-bottom: 2em;
}

.platform-panel {
    float: left;
    // width: 80%;
    // padding-left: 2em;
}

.platform-list {
    margin-bottom: 1.5em;
    padding: 1.2em;
    box-shadow: 0 0 10px #CCC;
    border-radius: $border-radius;
    cursor: pointer;
}

.platform-list .left-panel,
.platform-list .right-panel {
    float: left;
}

.platform-list .left-panel {
    width: 12%;
}

.platform-list .right-panel {
    width: 85%;
}

.platform-list .logo-box {
    position: relative;
    width: 100%;
    padding-top: 100%; // 1:1 ratio
    border-radius: 7px;
}

.platform-list .logo-box img {
    // 1:1 ratio
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}

.platform-list .platform-summary {
    padding-left: .7em;
}

.platform-list p {
    margin-bottom: 0;
}

.platform-list .link-panel {
    margin-top: 8px;
}

.platform-list .link-panel li {
    display: inline-block;
    list-style: none;
    width: 24px;
    height: 24px;
    // background: rgba(255, 255, 255, .4);
    margin-right: 5px;
    text-align: center;
    border-radius: 8px;
}

.platform-list .link-panel li a svg {
    width: 16px;
}

@media screen and (max-width: 1400px) {
    .platform-list {
        padding: 1em 1.2em;
    }

    .platform-list .platform-summary{
        padding-left: 1em;
    }
}

@media screen and (max-width: 1366px) {
    .platform-panel .row .col-md-4{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 1250px) {
    .platform-panel .row .col-md-4{
        flex: 0 0 50%;
        max-width: 50%;
    }
}

@media screen and (max-width: 768px) {
    .platform-panel .row .col-md-4{
        flex: 0 0 100%;
        max-width: 100%;
    }

    .platform-list {
        margin-bottom: 1em;
    }

    .platform-list .logo-box img{
        width: 70%;
    }

    .platform-list .platform-summary{
        padding-left: .5em;
    }
}

.category-tag{
    display: inline-block;
    background: #DDDDFF;
    color: #7769D0;
    padding: 0 10px;
    border-radius: 5px;
    margin-top: 8px;
    margin-right: 5px;
}

// Darke Mode
body.dark-mode .platform-list .link-panel li {
    // background: rgba(0, 0, 0, .5);
}