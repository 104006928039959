@import "../../index.scss";
.logo-wording {
    margin-left: 2em;
    margin-bottom: 0 !important;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    font-weight: 700 !important;
    // background: -webkit-linear-gradient(left, #3a3a9f, #d9a84e);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 1600px) {
    .logo-wording {
        margin-left: 0;
        font-size: 1.15rem;
    }
}

.sidebar {
    position: fixed;
    top: 0;
    width: 17%;
    height: 100vh;
    background: #EAF9E6;
    padding: 0 1.5em;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow-y: scroll;
}

.sidebar-logo {
    width: 70px;
    margin-left: 3em;
}

.sidebar li {
    display: block;
    font-weight: 600;
    margin: .2em 0;
    padding: .7em 2em;
}

.sidebar li.active {
    background: #f6fff4;
    border-radius: 7px;
}

.sidebar li a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.sidebar li a:hover {
    text-decoration: none;
}

.logo-panel {
    display: flex;
    align-items: center;
    // justify-content: center;
    height: 70px;
}

.category-icon-box {
    display: inline-block;
    // display: none;
    width: 26px;
    height: 26px;
    margin-right: .5em;
}

.category-icon-box img {
    // width: 75%;
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin: auto;
}

@media screen and (max-width: 1600px) {
    .sidebar {
        width: 19%;
    }
    .sidebar li {
        padding: .8em 2em;
    }
}

@media screen and (max-width: 1366px) {
    .sidebar {
        width: 23%;
    }
}

@media screen and (max-width: 1250px) {
    .sidebar {
        width: 22%;
    }
    .sidebar li {
        padding: .8em 1.5em;
    }
}

@media screen and (max-width: 1024px) {
    .sidebar {
        width: 26%;
    }
    .sidebar li {
        padding: .8em 1.5em;
    }
}

@media screen and (max-width: 768px) {
    .sidebar-wrapper {
        position: relative;
        width: 100%;
        height: 60px;
    }
    .sidebar .logo-panel {
        display: none;
    }
    .sidebar {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        background: #FFF;
        overflow: auto;
    }
    .sidebar::-webkit-scrollbar {
        display: none;
    }
    .sidebar li {
        display: flex;
        height: 37px;
        margin-right: 10px;
        padding: 0 10px;
        border: 1px solid #CCC;
        border-radius: 10px;
    }
    .sidebar li a {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }
    .category-icon-box {
        margin-right: 3px;
    }
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #d3decf;
}

body.dark-mode ::-webkit-scrollbar {
    width: 2px;
}


/* Track */

body.dark-mode ::-webkit-scrollbar-track {
    background: transparent;
}


/* Handle */

body.dark-mode ::-webkit-scrollbar-thumb {
    background: transparent;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// Dark mode
body.dark-mode .sidebar {
    background: #151515;
}

@media screen and (max-width: 768px) {
    body.dark-mode .sidebar {
        background: transparent;
    }
}

body.dark-mode .sidebar li.active {
    background: rgba(255, 255, 255, .05);
}

// Dark mode
body.neon-mode .sidebar {
    background: #000;
}

body.neon-mode .sidebar .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: #000;
    background: #06090f;
    background: #040609;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: -1;
}

body.neon-mode .sidebar::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 55%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    filter: blur(2px);
    box-shadow: 0 0 10px #e6df6e;
    z-index: -2;
}

body.neon-mode .sidebar::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 55%;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    filter: blur(2px);
    box-shadow: 0 0 10px #7474e4;
    z-index: -2;
}

/* Track */

body.neon-mode ::-webkit-scrollbar-track {
    background: transparent;
}


/* Handle */

body.neon-mode ::-webkit-scrollbar-thumb {
    background: transparent;
}

// Dark mode
body.neon-mode .sidebar {
    background: #151515;
}

@media screen and (max-width: 768px) {
    body.neon-mode .sidebar {
        background: transparent;
    }
}

body.neon-mode .sidebar li.active {
    background: #182236
}

@media screen and (max-width: 768px) {
    body.neon-mode .sidebar {
        background: transparent;
    }
    body.neon-mode .sidebar::before {
        content: none;
    }
    body.neon-mode .sidebar::after {
        content: none;
    }
}