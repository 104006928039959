.category-panel {
    margin-bottom: 1em;
    border-radius: 10px;
    box-shadow: 0 0 13px #dacaca;
    overflow: hidden;
}

.category-panel .title-panel{
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    padding: 1em 1em;
    background: #FFEEAE;
}

.category-panel .title-panel h3{
    color: #000;
    margin-bottom: 0 !important;
}

.category-panel .title-panel span{
    color: #888;
}

// .platform-list {
//     border-top: 1px solid #CCC;
//     padding: 1em;
//     cursor: pointer;
// }

// .category-panel .platform-list:first-child{
//     border-top: none;
// }

// .platform-list .left-panel,
// .platform-list .right-panel {
//     float: left;
// }

// .platform-list .left-panel {
//     width: 13%;
// }

// .platform-list .right-panel {
//     width: 87%;
// }

// .platform-list .logo-box {
//     position: relative;
//     width: 100%;
//     padding-top: 100%; // 1:1 ratio
//     background: #F1F1F1;
//     border-radius: 7px;
// }

// .platform-list .logo-box img { // 1:1 ratio
//     width: 70%;
//     position: absolute;
//     top: 0;
//     left: 0;
//     bottom: 0;
//     right: 0;
//     margin: auto;
// }

// .platform-list .platform-summary {
//     padding-left: .7em;
// }

// .platform-list p {
//     margin-bottom: 0;
// }