$shadow-color: #CCC;
$box-shadow: 0 0 10px $shadow-color;
$border-radius: 10px;
$box-shadow-dark: 0 0 15px rgba(0, 0, 0, .6);
// $dark-theme-color-primary: rgba(255, 255, 255, .15); // rgba(103, 103, 103, 0.5);
// $dark-theme-color-second: #000;
$dark-color-primary: #1e1e1e;
$dark-color-second: #282828;
$neon-color-primary: #0E1117;
$neon-color-second: #0E1117;
$neon-color-third: #000;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap');
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 0.95rem;
    // color: #c7c7c7;
    line-height: 1.4 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 768px) {
    body {
        font-size: 16px;
        padding-top: 45px;
        padding-bottom: 45px;
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
    margin-bottom: 0 !important;
}

a {
    color: inherit !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Nunito Sans', sans-serif;
    font-family: 'Inter', sans-serif;
    // font-family: 'Mulish', sans-serif;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.86rem;
    font-weight: 600 !important;
}

h1 {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    margin-bottom: 1.5em !important;
}

h3 {
    font-size: 1.1rem !important;
}

h6 {
    // font-weight: 700 !important;
    margin-bottom: 3px !important;
}

@media screen and (max-width: 768px) {
    h6{
        font-size: 0.97rem;
        font-weight: 700 !important;
    }
}
label {
    margin-bottom: 5px;
}

p {
    color: #616161;
}

.clear {
    clear: both;
}

.ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.ellipsis-1 {
    -webkit-line-clamp: 1;
}

.ellipsis-2 {
    -webkit-line-clamp: 2;
}

.card {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}

.flex-center {
    display: flex;
    align-items: center;
}

// DARK MODE
body.dark-mode {
    background: $dark-color-primary;
    color: #FFF;
    background: #1e1e1e;
}

body.dark-mode p {
    color: #c7c7c7;
}

// NEON MODE
body.neon-mode {
    background: $neon-color-primary;
    color: #FFF;
}

body.neon-mode p {
    color: #c7c7c7;
}